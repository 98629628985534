import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Typical from "react-typical";

import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={["Marcos", "Comelli", "Frontend", "Software", "Developer"]}
        title="About Me"
      />

      <section>
        <h2 className="inline-block p-3 mb-1 text-xl md:text-4xl font-bold bg-background-secondary">
          Hi! 👋🏼 I&apos;m Marcos Comelli
        </h2>

        <h3 className="py-3 mb-4 text-lg md:text-2xl font-bold text-blue-500">
          <Typical
            steps={["> Frontend software developer", 1000]}
            loop={Infinity}
            wrapper="p"
          />
        </h3>

        <h4 className="text-2xl font-bold">About Me</h4>
        <p className="text-lg mb-2 py-3">
          I&apos;m a software engineer living in Buenos Aires,{" "}
          <span className="font-bold bg-orange-200 text-copy-secondary rounded-lg p-1">
            Argentina
          </span>{" "}
          🇦🇷.
        </p>
        <p className="text-lg mb-2 py-3">
          I&apos;ve been interested in web development from a very young age. I
          remember myself experimenting with frontend technologies to build my
          first web page when I was just 14.
        </p>
        <p className="text-lg mb-2 py-3">
          My main focus is frontend{" "}
          <span className="font-bold bg-orange-200 text-copy-secondary rounded-lg p-1">
            (React.js, Vue.js)
          </span>{" "}
          but I also have considerable experience in backend technologies{" "}
          <span className="font-bold bg-orange-200 text-copy-secondary rounded-lg p-1">
            (Ruby on Rails)
          </span>
          . Check out my <a className="underline font-bold" href="https://marcosnc08.github.io/resume/">resume</a>.
        </p>
        <p className="text-lg mb-4 py-3">
          I finished my studies on computer engineering on 2018.
        </p>

        <div className="flex flex-wrap text-xs mb-4 w-3/4 text-center">
          <div className="mr-2 mb-2 p-1 rounded-lg bg-orange-200 dark:text-blue-900 bg-opacity-50">
            Javascript
          </div>
          <div className="mr-2 mb-2 p-1 rounded-lg bg-orange-200 dark:text-blue-900 bg-opacity-50">
            HTML
          </div>
          <div className="mr-2 mb-2 p-1 rounded-lg bg-orange-200 dark:text-blue-900 bg-opacity-50">
            CSS
          </div>
          <div className="mr-2 mb-2 p-1 rounded-lg bg-orange-200 dark:text-blue-900 bg-opacity-50">
            Ruby
          </div>
          <div className="mr-2 mb-2 p-1 rounded-lg bg-orange-200 dark:text-blue-900 bg-opacity-50">
            Docker
          </div>
          <div className="mr-2 mb-2 p-1 rounded-lg bg-orange-200 dark:text-blue-900 bg-opacity-50">
            React.js
          </div>
          <div className="mr-2 mb-2 p-1 rounded-lg bg-orange-200 dark:text-blue-900 bg-opacity-50">
            React Native
          </div>
          <div className="mr-2 mb-2 p-1 rounded-lg bg-orange-200 dark:text-blue-900 bg-opacity-50">
            Vue.js
          </div>
          <div className="mr-2 mb-2 p-1 rounded-lg bg-orange-200 dark:text-blue-900 bg-opacity-50">
            .Net
          </div>
          <div className="mr-2 mb-2 p-1 rounded-lg bg-orange-200 dark:text-blue-900 bg-opacity-50">
            PostgreSQL
          </div>
        </div>

        <h4 className="text-2xl font-bold mb-2">
          Want to talk? You can reach me here:
        </h4>
        <div className="text-gray-700 mb-2 py-3">
          <a href="mailto:marcosnc.08@gmail.com">
            <FontAwesomeIcon
              icon={faEnvelope}
              className="cursor-pointer text-3xl mr-4 mail-color"
            />
          </a>
          <a href="https://github.com/marcosnc08">
            <FontAwesomeIcon
              icon={faGithub}
              className="cursor-pointer text-3xl mr-4 github-color dark:text-white"
            />
          </a>
          <a href="https://www.linkedin.com/in/marcos-comelli/">
            <FontAwesomeIcon
              icon={faLinkedin}
              className="cursor-pointer text-3xl mr-4 linkedin-icon dark:text-white"
            />
          </a>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
